// searchModal search
let searchInput = document.querySelector('#searchModal #search')

function setSearch(val){
  	searchInput.value = val
}

// RANGE PRODUCT

$('.productImg-rangeBlok__range').on('input',function() {
	$(this).trigger('change');
	$('.productImg-rangeBlok__rangeIMG').css('width', $(this).val()/10 + '%')
})

$('.product-articles.close .product-articles__info').slideUp(500)

$('.product-articles__head').on('click', function() {
	let content = $(this).parent()
	if (!content.hasClass('close')) {
		content.addClass('close').find('.product-articles__info').slideUp(500)
	} else {
		content.removeClass('close').find('.product-articles__info').slideDown(500)
	}
})

// search in product

if ($('*').is('.broad-search')) {
	$(document).ready(function() {
		$('.js-example-basic-multiple-limit').select2({
			allowClear: true,
			maximumSelectionLength: 1,
		});
	
		$('.productFilterTop .js-example-basic-multiple-limit').select2({
			placeholder: "Действующее вещество",
			allowClear: true,
			maximumSelectionLength: 1,
		})
	
		$('.productFilterBott .js-example-basic-multiple-limit').select2({
			placeholder: "Проблема",
			allowClear: true,
			maximumSelectionLength: 1,
		})
	});
}

// product filter trigger
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
if(window.location.toString().indexOf('products') != -1){
	// Get the value of "some_key" in eg "https://example.com/products?group=10"
	let value = params.group; // "some_value"
	if( value != 0 && value != null ){
		document.querySelector('.resset-filter').classList.add('active');
	}
}
// 
// contacts
// 

// let contactSearch = $('#contactRegionSearch')
// let contactSearchContainer = $('.contact-search')
// let contactSearchClean = $('.contact-clean-search')

// contactSearchClean.on('click', function(){
// 	contactSearch.val('');
// 	contactSearchContainer.removeClass('active')
// 	inputSearch()
// })

// contactSearch.on('input', function(){
// 	inputSearch()
// })

// function inputSearch() {
// 	let inputVal = contactSearch.val()
// 	if (inputVal === '') {
// 		contactSearchContainer.removeClass('active')
// 	}
// 	else {
// 		contactSearchContainer.addClass('active')
// 	}

// 	if (inputVal !== '') {
// 		$('.search-content').slideDown(500)
// 		$('.static-content').slideUp(500)
// 	} else {
// 		$('.search-content').slideUp(500)
// 		$('.static-content').slideDown(500)
// 	}

// 	if (inputVal === 'search') {
// 		$('.search-result').slideDown(500)
// 		$('.search-none-found').slideUp(500)
// 	} else {
// 		$('.search-none-found').slideDown(500)
// 		$('.search-result').slideUp(500)
// 	}
// }

// contact tubs

let contactTub = $('.contacts-tabs .tab')

if (sessionStorage.getItem('contactTab') === null) sessionStorage.setItem('contactTab', 'regional')
let contactActiveTab = sessionStorage.getItem('contactTab')
let newTab = contactActiveTab

$('#'+contactActiveTab).addClass('active')
$('#'+contactActiveTab+'_ofice').addClass('visibility')

contactTub.on('click', function() {
	contactTub.removeClass('active');
	$(this).addClass('active');
	
	let ofice = $(this).attr('id')
	sessionStorage.setItem('contactTab', ofice)
	
	$('.wrapper960').removeClass('visibility')
	$('#'+ofice+'_ofice').addClass('visibility')

	newTab = sessionStorage.getItem('contactTab')

	searchClass()
})



// serch on tubs
searchClass()
function searchClass() {
	(function($) {
		$.fn.removeClassWild = function(mask) {
			return this.removeClass(function(index, cls) {
				var re = mask.replace(/\*/g, '\\S+');
				return (cls.match(new RegExp('\\b' + re + '', 'g')) || []).join(' ');
			});
		};
	})(jQuery);
	$('.contact-search').removeClassWild('search-*');
	$('.contact-search').addClass('search-'+newTab)
}


// ADAPTIVE MENU-STRING TRIGGER
$(function(){
	$('.menu-button > div.open-menu').on('click', function(){
		let $self = $(this);
		if( !$self.hasClass('active') ){
			$('nav.menu-string.adp').animateMenu([{y:-100},{y:0}]);
		} else {
			$('nav.menu-string.adp').animateMenu([{y:0},{y:-100}]);
		}
		$self.toggleClass('active');
	});
});
$.fn.animateMenu = function( v ){
	let $self = this;
	$(v[0]).animate(v[1], {
		duration: 500,
		step: function(val) {
			$self.css("transform", `translateY(${val}%)`); 
		}
	});
}
$.fn.shift = function( direction, type ) {
	// direction should be one of the four strings 
	// 'top', 'right', 'left' or 'bottom'
	// direction should be one of the two strings 
	// 'margin', 'padding'
	return parseInt(this.css(`${direction}-${type}`), 10);
};
// DESKTOP MAIN-MENU-STRING TRIGGER
$(function(){
	$('.menu-string.dsk').find('div.open-menu').on('click', function(){
		let $self = $(this);
		$self.toggleClass('active');
		$('.g-menu-wrap').slideToggle();
	});
});
// LANG SELECT
$(function(){
	$('.used-lang').on('click', function(){
		let $self = $(this),
			$lang_menu = $self.closest('.lang').find('.select-menu'),
			lang_menu_base_height = 35,
			lang_padding = $lang_menu.shift('padding', 'top') + $lang_menu.shift('padding', 'bottom');

		if( $lang_menu.hasClass('expand') ){
			// hide
			$lang_menu.animate({height:`${lang_menu_base_height}px`}, 250, function(){ $lang_menu.fadeOut(250); });
			$self.removeClass('open');
			$lang_menu.removeClass('expand');
		} else {
			// show
			$lang_menu.fadeIn(250).promise()
				.done(function(){
					let	lang_item_height = $lang_menu.find('.menu-item').height(),
						lang_item_margin = $lang_menu.find('.menu-item').shift('margin', 'bottom'),
						lang_item_count = $lang_menu.find('.menu-item').length,
						lang_full_height = 0;

					if( lang_item_count > 2 ){
						lang_full_height = 10 + (lang_item_height * lang_item_count) + ((lang_item_count - 1) * lang_item_margin) + lang_padding;
					} else {
						lang_full_height = 10 + (lang_item_height * lang_item_count) + lang_item_margin + lang_padding;
					}
					$lang_menu.animate({height:`${lang_full_height}px`}, 250);
				});
			$self.addClass('open');
			$lang_menu.addClass('expand');
		}
	});
});
// PRESS CENTER MENU TRIGGER
$(function(){
	$('.sw-head > div.btn').on('click', function(){
		// read pointer from click
		let pointer = $(this).attr('data-switch');
		if( $(this).hasClass('active') ){
			// if click on active, close menu only
			$(this).removeClass('active');
			$('.sw-body.active').fadeOut(75).promise()
				.done(function(){
					$('.sw-body.active').removeClass('active');
				});
		} else {
			// if click in none-active close opened & open target
			$('.sw-head').find('div.btn.active').removeClass('active');
			$(this).addClass('active');
			$('.sw-body.active').fadeOut(75).promise()
				.done(function(){
					$('.sw-body.active').removeClass('active');
					$(`.sw-body[data-sw-body="${pointer}"]`).fadeIn(75).promise()
						.done(function(){
							$(`.sw-body[data-sw-body="${pointer}"]`).addClass('active');
						});
				});
		}
	});
	// init calendar (press-news)
	if( $('section.press-center').hasClass('news') || $('section.press-center').hasClass('list') ){
		var mQuery = window.matchMedia('screen and (max-width: 768px)');
		if( mQuery.matches ){
			$('.calendar-wrap').datepicker({
				showOtherMonths: true,
				// monthNames: [ "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
				monthNames: [ "January","February","March","April","May","June","July","August","September","October","November","December"],
				firstDay: 1,
				dateFormat: 'dd.mm.yy',
				onSelect: function( selectDate ){
					let $self = $(this);
					if( !$self.data().datepicker.first ){
						$self.data().datepicker.inline = true;
						$self.data().datepicker.first = selectDate;
						$('input.date-holder[data-pointer="date-from"]')
							.val($self.data().datepicker.first);
					} else {
						if( selectDate > $self.data().datepicker.first ){
							$('input.date-holder[data-pointer="date-to"]').val(selectDate);
							$('input.date-holder[data-pointer="date-from"]').val($self.data().datepicker.first);
						} else {
							$('input.date-holder[data-pointer="date-to"]').val($self.data().datepicker.first);
							$('input.date-holder[data-pointer="date-from"]').val(selectDate);
						}
						$self.data().datepicker.inline = false;
					}
				}
			});
		} else {
			$('.input-wrap').datepicker({
				showOtherMonths: true,
				// monthNames: [ "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
				monthNames: [ "January","February","March","April","May","June","July","August","September","October","November","December"],
				firstDay: 1
			}).on('change', function(){
				// place selected date to input field
				let $self = $(this),
				dtp_date = $self.datepicker('getDate');
				
				$self.find('input.date-holder').val($.datepicker.formatDate('dd.mm.yy', dtp_date));
				$self.find('input.date-holder').removeClass('red');
			});
		}
		$('.btn.clnd').on('click', function(){
			let $self = $(this);
			if( $self.attr('data-target') == 'ok' ){
				// pass data
				let d_from = $('input.date-holder[data-pointer="date-from"]').val(),
						d_to = $('input.date-holder[data-pointer="date-to"]').val();
				if( d_from != '' && d_to != '' ){
					window.location = `/news?date_from=${d_from}&dat-to=${d_to}`;
				} else {
					if( d_from == '' ){
						$('input.date-holder[data-pointer="date-from"]').addClass('red');
					}
					if( d_to == '' ){
						$('input.date-holder[data-pointer="date-to"]').addClass('red');
					}
				}
				// close expander
				// $('.btn[data-switch="calendar"]').trigger('click');
			} else if ( $self.attr('data-target') == 'cancel' ){
				if( $('input.date-holder[data-pointer="date-to"]').val() == 
						$('input.date-holder[data-pointer="date-from"]').val() && 
						$('input.date-holder[data-pointer="date-to"]').val() == '' ){
							
							$('.btn[data-switch="calendar"]').trigger('click');
				}
				$('input.date-holder').val('');
				$('.calendar-wrap').datepicker('setDate', null);
				$('.calendar-wrap').data().datepicker.first = '';
			}
		});
	}
});

// FILE-LIKE TRANSLATOR
$(function(){
	$.get('/js/translate.json', function(ret){
		let uri = window.location.href.split('/'),
			lang = $.inArray('ru', uri) == -1 ? 'ru' : 'kz';
		$(document).find('[data-translate="1"]').each(function(__n, el){
			let $el = $(el),
					body = JSON.parse($el.attr('data-tr'));
			if( body.type == 1 ){ // select2
				if($el.hasClass('ftop')){
					$('.productFilterTop .js-example-basic-multiple-limit').select2('destroy');
					$('.productFilterTop .js-example-basic-multiple-limit').select2({
						placeholder: ret[body['key']][lang],
						allowClear: true,
						maximumSelectionLength: 1,
					});
					if( lang == 'kz' ){
						$('.productFilterTop').find('textarea.select2-search__field').attr('data-lang', 'kz');
					}
				}
				if($el.hasClass('fbot')){
					$('.productFilterBott .js-example-basic-multiple-limit').select2('destroy');
					$('.productFilterBott .js-example-basic-multiple-limit').select2({
						placeholder: ret[body['key']][lang],
						allowClear: true,
						maximumSelectionLength: 1,
					})
				}
			}
			if( body.type == 2 ){
				$(body["selector"]).attr(body["attr_name"], ret[body['key']][lang]);
			}
		});
	});
});

// SERVICES PAGE HEIGHT UPDATE
$(function(){
	if( $('section.services.catalog').length !== 0 ){
		let $body = $('article.wrap-content:not(.empty)'),
			count = parseInt($body.attr('data-gcount'), 10);
		count = isNaN(count) ? 0 : count;
		if( count == 0 ){
			$('article.wrap-content.empty').height($('aside.body-filter').height());
		} else if( count <= 3 ){
			$('article.wrap-content:not(.empty)').height($('aside.body-filter').height());
		}
	}
});

// BEEHIVE FIX
$(function(){
	var mQuery = window.matchMedia('screen and (max-width: 768px)');
	if(!mQuery.matches){
		let rows = $('.beehive').find('.h-row');
		let last = rows[rows.length -1];
		if( rows.length % 2 == 0 ){
			if( $(last).find('.item').length == 1 ){
				$(last).css('justify-content', 'center');
			} 
			if( $(last).find('.item').length == 2 ){
				$(last).css('justify-content', 'space-between');
				$(last).find('.item').eq(0).css('margin-left', '160px');
				$(last).find('.item').eq(1).css('margin-right', '160px');
			} 
		}
	}
});
// FILTER SIDEBAR SLIDE
$(function(){
	var mQuery = window.matchMedia('screen and (max-width: 768px)');
	if( mQuery.matches ){
		$('aside.body-filter span.body-filter-alt').on('click', function() {
			let content = $(this).closest('.body-filter');
			if (!content.hasClass('close')) {
				content.addClass('close').find('ul.type').slideUp(500);
				content.addClass('close').find('.alphabet').slideUp(500);
			} else {
				content.removeClass('close').find('ul.type').slideDown(500);
				content.removeClass('close').find('.alphabet').slideDown(500);
			};
		});
		$('.sitebar.product-sitebar span.body-filter-alt').on('click', function() {
			let content = $(this).closest('.sitebar.product-sitebar');
			if (!content.hasClass('close')) {
				content.addClass('close').find('.drugs-filter').slideUp(500);
				content.addClass('close').find('.culture-filter').slideUp(500);
				content.addClass('close').find('.broad-search ').slideUp(500);
			} else {
				content.removeClass('close').find('.drugs-filter').slideDown(500);
				content.removeClass('close').find('.culture-filter').slideDown(500);
				content.removeClass('close').find('.broad-search ').slideDown(500);
			};
		});
		$('aside.body-filter span.body-filter-alt').trigger('click');
		$('.sitebar.product-sitebar span.body-filter-alt').eq(0).trigger('click');
	}
	// METEO CAT TRIGGER
	$('.day-grid .item h6').on('click', function() {
		let content = $(this).closest('.item');
		if (content.hasClass('open')) {
			content.removeClass('open').find('.legend').slideDown(500);
			content.removeClass('open').find('.data').slideDown(500);
		} else {
			content.addClass('open').find('.legend').slideUp(500);
			content.addClass('open').find('.data').slideUp(500);
		};
	});
});
// COMPANY EXPANDER
$(function(){
	$('.company-info .expander span.arrow').on('click', function(){
		let $btn = $(this);
		console.log($btn.hasClass('less'))
		if( $btn.hasClass('less') ){
			$btn.closest('.info-body').find('.info-content').animate({'height': '450px'}, 500);
		} else {
			$btn.closest('.info-body').find('.info-content').animate({'height': '3090px'}, 500);
		}
		$(this).toggleClass('less');
	});
});
// CIRCUIT TABLE-HEAD STICKY (ADAPTIVE)
$(function(){
	var mQuery = window.matchMedia('screen and (max-width: 768px)');
	if( mQuery.matches && $('.wrapper.circuit').length !== 0 ){
		let startPos = 585,
			endPos = ($('div.table-body').height() + $('div.table-body').offset().top) - startPos; //(table height + table shift) - start scrolling

		let $numb = $("table.circuit_item").find('tr.stage-numb'),
				$info = $("table.circuit_item").find('tr.stage-info');

		let numb_top = 79,
			info_top = 129;
		$(document).on('scroll', () => {
			if( $(this).scrollTop() >= startPos ){
				$numb.addClass("fixed");
				$info.addClass("fixed");
				$("table.circuit_item").find('thead').addClass("buffer");
			} else if( $(this).scrollTop() < startPos ){
				$numb.removeClass("fixed");
				$info.removeClass("fixed");
				$("table.circuit_item").find('thead').removeClass("buffer");
			}
			if( $(this).scrollTop() >= endPos ){
				let numb = (numb_top - ($(this).scrollTop() - endPos)),
					info = (info_top - ($(this).scrollTop() - endPos));
				$numb.css({'top': `${numb}px`});
				$info.css({'top': `${info}px`});
			} else {
				$numb.css({'top': `${numb_top}px`});
				$info.css({'top': `${info_top}px`});
			}
		});
		$('div.table-body').on('scroll', () => {
			let scroll = $('div.table-body').scrollLeft();

			$numb.css({'left': `${18 - scroll}px` });
			$info.css({'left': `${18 - scroll}px` });
		});
	}
});